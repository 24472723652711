export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 150
  },
  {
    title: '液位仪编号',
    key: 'meterNum',
    minWidth: 100
  },
  {
    title: '油罐号',
    key: 'tankNum'
  },
  {
    title: '报警温度',
    key: 'tankTemp'
  },
  {
    title: '报警内容',
    key: 'msg'
  },
  // {
  //   title: '离线时间',
  //   key: 'createTime'
  // },
  // {
  //   title: '恢复时间',
  //   key: 'recoveryTime'
  // }
  {
    title: '报警时间',
    key: 'saveTime'
  }
];
